<template>
  <div v-if="pwaSupport" class="pwa-widget">
    <div class="pwa-widget__header" :class="{ 'is-mobile': device === DeviceEnum.MOBILE }">
      <div class="content">
        <rich-text v-model="model.title"  :editing="editing" :disabled="!editing" no-tool-bar theme="snow" placement="bottom" class="describe" />
        <div class="pwa-button" @click="handleDownload">{{ model.buttonName }}</div>
      </div>
    </div>
    <div v-if="drawerVisible" class="pwa-widget__drawer" :class="{ 'is-mobile': device === DeviceEnum.MOBILE, 'is-edit': editing }">
      <i class="el-icon-close text-black" @click="handleClose" />
      <rich-text v-model="model.modalTitle" :editing="editing" :disabled="!editing" placement="bottom" theme="snow" />
      <div class="content">
        <rich-text v-model="model.modalSubtitle" class="mt-4" :editing="editing" no-tool-bar :disabled="!editing" />
        <div class="relative mt-5">
          <cover-block
            v-if="editing"
            :title="$t('edit.setImage')"
            class="cover-btn"
            @click.native="handleShowImg"
          />
          <img :src="model.guidBackground.backgroundImage" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RichText from "~/components/richText/index.vue";
import {DeviceEnum} from "~/enums/deviceEnum";
import CoverBlock from "~/components/common/CoverBlock.vue";
export default {
  components: {CoverBlock, RichText},
  props: {
    device: {
      type: String,
      default: ''
    },
    editing: {
      type: Boolean,
      default: false
    },
    model: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      prompt: null
    }
  },
  computed: {
    DeviceEnum() {
      return DeviceEnum
    },
    drawerVisible() {
      let result = false
      const isMobile = this.device === DeviceEnum.MOBILE
      if ((this.editing && isMobile) && this.$store.state.site.pwaDrawerVisible) {
        result = true
      } else if (!this.editing && this.$store.state.site.pwaDrawerVisible) {
        result = true
      }
      return result
    },
    pwaSupport() {
      let result = false
      if (this.editing && this.$store.state.site.pwaVisible) {
        result = true
      } else if ((!this.editing && this.$store.state.site.pwaPrompt) && this.$store.state.site.pwaVisible) {
        result = true
      }
      return result
    }
  },
  mounted() {
    //
  },
  beforeDestroy() {
   //
  },
  methods: {
    handleClose() {
      this.$store.commit('site/SET_PWA_DRAWER_VISIBLE', false)
    },
    handleShowImg() {
      this.SiteMenu.showImageWithKey('pwa-guid-image')
    },
    handleDownload() {
      const isMobile = this.device === DeviceEnum.MOBILE
      if (isMobile) {
        this.$store.commit('site/SET_PWA_DRAWER_VISIBLE', true)
      } else if (!isMobile && !this.editing) {
        this.$store.state.site.pwaPrompt.prompt()
      }
    }
  }
}
</script>

<style lang="less">
.pwa-widget {
  &__header {
    width: 100%;
    height: 86px;
    z-index: 200;
    background-color: rgba(0, 0, 0, 1);
    .content {
      max-width: 1280px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 100%;
      padding: 0 100px;
      .pwa-button {
        background-color: var(--button-background-color);
        padding: 10px 15px;
        color: currentColor;
        cursor: pointer;
        border-radius: 30px;
      }
    }
    &.is-mobile {
      height: 60px;
      .content {
        width: 100%;
        padding: 0 20px;
        .describe {
          span {
            font-size: 14px !important;
          }
        }
        .pwa-button {
          padding: 5px 10px;
        }
      }
    }
  }
  &__drawer {
    position: fixed;
    background-color: white;
    z-index: 200;
    padding: 40px 40px 0 40px;
    border-top: 1px solid #dedede;
    &.is-mobile {
      left: 0;
      bottom: 0;
      width: 100%;
      transform: none;
    }
    &.is-edit.is-mobile {
      left: calc(50% + 12px);
      transform: translate(-50%, 0);
      bottom: 0;
      width: @edit-mobile-w;
    }
    .el-icon-close {
      position: absolute;
      top: 20px;
      right: 20px;
      color: black;
      font-size: 18px;
      cursor: pointer;
    }
    .content {
      height: 400px;
      overflow-y: auto;
      .cover-btn {
        position: absolute !important;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
</style>
