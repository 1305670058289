<template>
  <div class="carousel-menu-wrap">
    <menu-title :close="close">{{ modelTitle }}</menu-title>
    <menu-item class="pt-0" label="背景填充">
      <menu-fill
        :target="model.children[model.__activeIndex]"
        target-key="children"
        :target-index="model.__activeIndex"
        color-key="backgroundColor"
        eye-key="backgroundColorShow"
      />
      <div class="my-3 text-white text-12">
        {{ $t('common.pc') }}
        <span
          class="text-textColor-3"
        >({{ $t('upload.suggestionExtra', { w: 3, h: 1 }) }})</span>
      </div>
      <menu-background-image
        class="mt-2"
        :target="model.children[model.__activeIndex]"
        target-key="children"
        :target-index="model.__activeIndex"
        eye-key="backgroundImageShow"
      />
    </menu-item>
    <menu-divider />
    <nav-edit class="mt-4" :target="model.children[model.__activeIndex]" target-key="children" :target-index="model.__activeIndex" label="轮播图跳转事件" />
<!--    <menu-item label="轮播图跳转">-->
<!--      <div class="image_skip p-2 mt-2">-->
<!--        <div class="mb-1">跳转链接</div>-->
<!--        <imPageInput-->
<!--          v-model="model.children[model.__activeIndex].img_url"-->
<!--          :suggest-list="SitePage.list || []"-->
<!--          size="small"-->
<!--          @input="()=> model.children[model.__activeIndex].img_good = ''"-->
<!--        />-->
<!--      </div>-->
<!--      <div class="image_skip p-2">-->
<!--        <div class="mb-1">商品</div>-->
<!--        <im-select-->
<!--          v-model="model.children[model.__activeIndex].img_good"-->
<!--          :placeholder="''"-->
<!--          size="small"-->
<!--          :options="storeGoodsList"-->
<!--          label-key="name"-->
<!--          filterable-->
<!--          @change="()=> model.children[model.__activeIndex].img_url = ''"-->
<!--        ></im-select>-->
<!--      </div>-->
<!--    </menu-item>-->
    <menu-divider />
    <menu-item label="按钮">
      <template v-for="(item, index) in model.children[model.__activeIndex].buttons">
        <nav-edit :key="item.id" class="mt-4" :target="item" show-name name-key="name" show-remove show-bg @remove="() => onButtonRemove(item, index)" />
      </template>

<!--      <item-wrapper-->
<!--        v-for="(item, index) in model.children[model.__activeIndex].buttons"-->
<!--        :key="index"-->
<!--        class="mt-2"-->
<!--        show-remove-->
<!--        :title="`按钮${index + 1}`"-->
<!--        :model="item"-->
<!--        :form="buttonForm"-->
<!--        @remove="() => onButtonRemove(item, index)"-->
<!--      />-->
    </menu-item>
    <menu-button class="mt-4" type="ghost" icon="add" @click.native="handleAddButton">添加按钮</menu-button>
    <delete-block-trigger class="mt-4" :is-delete-block="false" @delete-block="onDelete" />
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import cloneDeep from "lodash.clonedeep";
import deleteBlockTrigger from './deleteBlockTrigger.vue'
import NavEdit from "~/site/components/navEdit.vue";
import menuTitle from '~/components/menu/menuTitle';
import MenuButton from "~/components/menu/menuButton";
import MenuItem from '~/components/menu/menuItem'
import menuFill from "~/components/menu/menuFill"
import menuBackgroundImage from "~/components/menu/menuBackgroundImage";
import MenuDivider from "~/components/menu/divider"
import SitePage from '~/site/core/page'
// import imPageInput from "~/components/im30/searchInput";
import {insertStep} from "~/utils/event";

export default {
  name: 'EditMenuBackground',
  components: {
    NavEdit,
    MenuDivider,
    MenuItem,
    MenuButton,
    menuTitle,
    deleteBlockTrigger,
    menuFill,
    menuBackgroundImage,
    // imPageInput
  },
  props: {
    model: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      colorList: [
        {
          value: '#151724'
        },
        {
          value: 'white'
        },
        {
          value: '#C4C4C4'
        },
        {
          value: '#000000'
        }
      ],
    }
  },
  computed: {
    ...mapState({
      storeGoods: state => state.site.storeGoods
    }),
    storeGoodsList() {
      return this.storeGoods.reduce((a, v) => [
        ...a,
        ...(v.__goods || []).map((g) => ({
          id: g?.id,
          name: `${g?.group_name?.lang_id || g?.group_name?.en} - ${g?.names?.lang_id || g?.names?.en}`,
          value: `store-good-id-${g.id}`
        }))
      ], [])
    },
    modelTitle() {
      return this.model.__editTitle || this.model.title
    },
    buttonForm() {
      const vm = this
      return [
        {
          label: '名称',
          prop: 'name',
          type: 'input',
          value: '',
        },
        {
          label: '跳转链接',
          prop: 'url',
          type: 'pageInput',
          list: SitePage.list,
          value: '',
        },
        {
          label: '商品',
          prop: 'good',
          type: 'goodInput',
          list: vm.storeGoodsList,
          value: '',
        },
      ]
    },
  },
  methods: {
    ...mapMutations({
      'ADD_MENU_KEY': 'menu/ADD_MENU_KEY',
      'REMOVE_MENU_KEY': 'menu/REMOVE_MENU_KEY'
    }),
    onButtonRemove(item, index) {
      const buttons = cloneDeep(this.model.children[this.model.__activeIndex].buttons)
      this.model.children[this.model.__activeIndex].buttons.splice(index, 1)
      insertStep(this, ['children', this.model.__activeIndex, 'buttons'], buttons)
    },
    close() {
      this.SiteMenu.close('carousel')
    },
    handleAddButton() {
      this.model.__addButton(this.model.__activeIndex)
    },
    refresh() {
      this.$nextTick(() => {
        this.model.__refresh()
      })
    },
    onDelete() {
      insertStep(this, ['children'], cloneDeep(this.model.children), () => {
        this.refresh()
      })
      this.$store.commit('editor/CLEAR_STORAGE_STEP')
      this.close()
      this.model.children.splice(this.model.__activeIndex, 1)
      this.model.__activeIndex = ''
      this.refresh()
    },
    handleShowColor() {
      this.$refs.color.$el.querySelector('.el-color-picker__trigger').click()
    }
  }
}
</script>

<style lang="less" scoped>
.carousel-menu-wrap {
  .image_skip {
    background: @fill-color-2;
  }
}
</style>
