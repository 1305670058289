<template>
  <div
    ref="quillWrapRef"
    class="quill-wrap"
    :style="editStyle"
    :class="[theme, placement]"
    @click.stop="handleClick"
  >
    <client-only>
      <div v-if="disabled" class="rich-text-block">
        <div class="ql-editor" v-html="data"></div>
      </div>
      <div v-else class="rich-text" :class="[...className, isMobile ? 'wrap' : '']">
        <quill-editor
          ref="editor"
          v-model="data"
          :options="options"
          @change="debounceChange"
          @focus="onFocus"
          @blur="onBlur"
        />
      </div>
    </client-only>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import debounce from 'lodash.debounce'
import { uid } from 'uid'
import { DeviceEnum } from '~/enums/deviceEnum'

export default {
  name: 'RichText',
  props: {
    value: [String, Object, Number],
    placeholder: {
      type: String,
      default: '',
    },
    model: {
      type: Object,
      default() {
        return {}
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    theme: {
      type: String,
      default: 'snow'
    },
    noToolBar: {
      type: Boolean,
      default: false
    },
    editing: {
      type: Boolean,
      default: false
    },
    placement: {
      type: String,
      default: 'top'
    },
    toolbar: {
      type: Array,
      default: () => {
        return [
          // Note false, not 'normal', is the correct value. quill.format('size', false) removes the format, allowing default styling to work
          [{ 'font': [] }, { 'size': ['small', false, 'large'] }, 'bold', 'italic', 'underline', 'strike'],
          [{ 'color': [] }, { 'background': [] }],
          [{ 'script': 'sub' }, { 'script': 'super' }],
          [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'align': [] }],
          ['link']
        ]
      }
    },

    mobileLayout: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      data: this.default,
      debounceChange: Function,
      focus: false,
      id: uid(),
      pickers: [],
      pickerLabels: [],
      labelParentMap: new WeakMap(),
    }
  },

  computed: {
    ...mapState({
      device: state => state.editor?.device,
    }),

    isMobile() {
      return this.mobileLayout || this.device === DeviceEnum.MOBILE
    },

    editStyle() {
      if (this.editing) {
        return {
          // marginLeft: '-30px',
          // paddingLeft: '30px'
        }
      }
      return {}
    },
    className() {
      const result = ['editor-editing']
      if (this.disabled) {
        result.push('disabled')
      }
      if (this.noToolBar) {
        result.push('editor-toolbar-empty')
      }
      const action = this.$store.state.editor.currentRichTextId === this.id ? 'focus-editor' : 'blur-editor'
      result.push(action)
      return result
    },
    options() {
      const toolbar = this.noToolBar ? [] : this.toolbar
      return {
        theme: this.theme,
        placeholder: this.placeholder,
        modules: {
          toolbar
        }
      }
    }
  },
  watch: {
    value(n) {
      this.data = n && n.toString()
    },
    '$store.state.editor.currentRichTextId'(n, o) {
      if (n === null) {
        this.$refs.editor && this.$refs.editor.quill.blur()
      }
    },
  },

  mounted() {
    this.debounceChange = debounce(this.onChange, 800)
    this.data = this.value ? this.value.toString() : ''
    this.fixOtherPickerClose()
  },

  destroyed() {
    this.dropPickerEvent()
  },

  methods: {
    /**
     * 修复: quill选择器弹窗互斥出现失效的问题
     */
    fixOtherPickerClose() {
      this.$nextTick(() => {
        this.pickers = document.querySelectorAll('.rich-text .ql-picker')
        this.pickerLabels = document.querySelectorAll('.rich-text .ql-picker .ql-picker-label')
        this.pickerLabels.forEach(ele => {
          ele.addEventListener('click', this.otherPickerCloseHandler)
        })
      })
    },

    dropPickerEvent() {
      this.pickerLabels.forEach(ele => {
        ele.removeEventListener('click', this.otherPickerCloseHandler)
      })
      this.labelParentMap = null;
    },

    otherPickerCloseHandler(e) {
      const parent = this.findPickerParent(e.target)
      this.labelParentMap.set(e.target, parent);
      this.pickers.forEach(pr => {
        if (pr !== parent) {
          pr.classList.remove('ql-expanded')
        }
      })
    },

    findPickerParent(ele) {
      if (this.labelParentMap.has(ele)) {
        return this.labelParentMap.get(ele);
      }
      return !ele || (ele && ele.classList.contains('ql-picker'))
        ? ele
        : this.findPickerParent(ele.parentNode);
    },

    handleClick() {
      this.__edit_quill = true // 记录是否是手动编辑模式
      this.__oldChangeData = null
      if (this.editing) {
        this.$store.commit('editor/SET_CURRENT_RICH_TEXT_ID', this.id)
      }
    },
    onFocus() {
      if (!this.$refs.editor) return
      this.$refs.editor.quill
        .getModule('toolbar')
        .addHandler('color', (value) => {
          this.$refs.editor.quill.format('color', value || '#000000')
        })
    },
    onBlur() {
      this.__edit_quill = false // 记录是否是编辑模式，初始化时会触发blur暂时不处理
      this.__oldChangeData && this.onChange(this.__oldChangeData) // 记录上次变更的值，blur之后再去更新
    },
    onChange(data) {
      this.__oldChangeData = data // 记录上次变更的值，blur之后再去更新
      // blur 的时候去更新model
      if (!this.__edit_quill) {
        this.$emit('input', data.html)
        this.$emit('onChangeText', data.html)
      }
    }
  }
}
</script>

<style lang="less">
.rich-size(@size, @weight: unset) {
  .rich-text-block,
  .rich-text {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: @size;
      font-weight: @weight;
    }
  }
}

.editor-editing {
  .ql-container.ql-snow {
    &:hover {
      border: 1px solid @primary-color  !important;
    }
  }
}

.quill-wrap {
  .rich-size(unset);

  .ql-container {
    font-size: unset;
    font-family: @siteFontFamily;
  }

  &:hover {
    .rich-text.blur-editor {
      .ql-container.ql-snow {
        border: 1px solid @primary-color  !important;
      }
    }
  }
}

.ql-editor {
  padding: 0;
  // word-break: break-all; // 取消单词内换行
}

.quill-wrap.bubble {
  border: 1px solid @border-color-base;
  border-radius: @border-radius-base;

  .ql-bubble .ql-picker.ql-expanded .ql-picker-options {
    background-color: @dark-bg;
  }
}

.quill-wrap {
  &.top {
    .ql-toolbar.ql-snow {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -105%);
    }
  }

  &.bottom {
    .ql-toolbar.ql-snow {
      position: absolute;
      top: 105%;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }

  &.left-top {
    .ql-toolbar.ql-snow {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(0, -105%);
    }
  }

  &.right-top {
    .ql-toolbar.ql-snow {
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(0, -105%);
    }
  }
}

.rich-text {
  position: relative;
  align-items: center;

  .ql-picker.ql-header {

    .ql-picker-label::before,
    .ql-picker-item::before {
      content: '文本';
    }
    .ql-picker-label[data-value='1']::before,
    .ql-picker-item[data-value='1']::before {
      content: '标题';
    }
    .ql-picker-label[data-value='2']::before,
    .ql-picker-item[data-value='2']::before {
      content: '描述';
    }
    .ql-picker-label[data-value='3']::before,
    .ql-picker-item[data-value='3']::before {
      content: '标题3';
    }
    .ql-picker-label[data-value='4']::before,
    .ql-picker-item[data-value='4']::before {
      content: '标题4';
    }
    .ql-picker-label[data-value='5']::before,
    .ql-picker-item[data-value='5']::before {
      content: '标题5';
    }
    .ql-picker-label[data-value='6']::before,
    .ql-picker-item[data-value='6']::before {
      content: '标题6';
    }
  }

  &.focus-editor {
    .ql-toolbar.ql-snow {
      display: flex;
      flex-wrap: nowrap;
    }

    &.wrap {
      .ql-toolbar.ql-snow {
        display: flex;
        flex-wrap: wrap;
      }
    }

    .ql-toolbar.ql-snow .ql-formats {
      display: flex;
      flex-wrap: nowrap;
    }

    .ql-container.ql-snow {
      background-color: #1a1837;
      border: 1px solid @primary-color !important;
    }
  }

  &.blur-editor {
    .ql-toolbar.ql-snow {
      display: none;
    }

    .ql-container.ql-snow {
      border: 1px solid transparent;
    }
  }

  .ql-toolbar.ql-snow {
    display: none;
    padding: 5px;
    letter-spacing: normal;
    white-space: nowrap;
    border: 0;
    background-color: @primary-3;
    width: fit-content;
    border-radius: 4px;
    line-height: 20px;
    z-index: 100;

    .ql-header {
      background-color: @fill-color-2;
    }
    button,
    .ql-picker-label {
      &:hover {
        .ql-stroke {
          stroke: white;
        }

        .ql-fill {
          fill: white;
        }
      }
    }

    button.ql-active {
      .ql-stroke {
        stroke: @primary-color;
      }

      .ql-fill {
        fill: @primary-color;
      }
    }

    .ql-stroke {
      stroke: @text-3-hex;
    }

    .ql-picker-options {
      .ql-stroke {
        stroke: #444;
      }
    }
  }

  .ql-snow .ql-picker-label {
    color: @text-3-hex;

    &.ql-active {
      .ql-stroke {
        color: @primary-color;
        stroke: @primary-color;
      }
    }
  }
  .ql-align,
  .ql-background,
  .ql-color {
    .ql-picker-label {
      padding-top: 0;
      color: white;
    }
  }
  .ql-snow .ql-picker.ql-expanded {
    .ql-picker-label {
      color: @text-3-hex;
    }

    .ql-picker-options {
      @apply z-50;
    }
  }

  .ql-container.ql-snow {
    border: 1px solid transparent;
  }
}

.editor-toolbar-empty {
  &.focus-editor {
    .ql-toolbar.ql-snow {
      @apply hidden p-0 m-0;
    }
  }
}
</style>
