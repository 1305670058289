<template>
  <el-dialog class="lottery-rule" :title="$t('siteBuild.lotteryWidget.ruleTitle')" :width="$store.getters.isMobile ? '100%' : '520px'" :visible.sync="visible">
    <div class="lottery-rule__content">
      <p>{{ model.rule1 }}</p>
      <p>{{ model.rule2 }}</p>
    </div>
  </el-dialog>
</template>

<script>
import { Dialog } from 'element-ui'
export default {
  name: "LotteryRule",
  components: {
    [Dialog.name]: Dialog
  },
  props: {
    model: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      visible: false
    }
  },
  methods: {
    init() {
      this.visible = true
    }
  }
}
</script>

<style lang="less">
.lottery-rule {
  .el-dialog__header {
    font-weight: bold;
    text-align: center;
    .el-dialog__title {
      font-size: 18px;
    }
  }
}
</style>
