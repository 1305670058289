<template>
  <div>
    <div v-imloading="checking" class="user-id-wrap form-wrap mt-[32px]">
      <div class="form-item">
        <div
          class="relative label"
          :style="{ color:mainTextColor }"
        >{{ $t('siteBuild.header.userId') }}</div>
        <div class="flex items-center input-wrap">
          <site-input
            :value="id"
            :style="mainButtonColor"
            :clearable="true"
            :placeholder="$t('siteBuild.header.placeHolderUserID')"
            @clear="clear"
            @blur="checkUser"
            @input="valueChange"
          />
        </div>
      </div>
    </div>
    <div v-if="checked" class="relative user-info mt-[8px] rounded">
      <div class="user-info-item">
        <label>{{ $t('siteBuild.header.userName') }}</label>：
        <span>{{ currUserInfo && currUserInfo.name }}</span>
      </div>
      <div class="user-info-item">
        <label>{{ $t('siteBuild.header.warZone') }}</label>：
        <span>{{ currUserInfo && currUserInfo.server }}</span>
      </div>
    </div>
    <div :class="['relative content', !isMobile ? 'mt-[32px]' : 'mt-[36px]']">
      <rich-text
        v-if="model.helperImageShow && model.helperImage"
        key="helperImageText"
        v-model="model.helperImageText"
        :editing="editing"
        :disabled="!editing"
        theme="snow"
        :mobile-layout="true"
        class="text-14 mb-1"
        :style="{ color: mainTextColor }"
      />

      <HelperImage
        v-if="model.helperImageShow"
        key="helperImageShow"
        class="hepler-image"
        :editing="editing"
        :model="model"
      />
    </div>
    <div class="relative footer">
      <Button
        v-imloading="logining"
        size="large"
        :style="mainButtonColor"
        :class="[!isMobile ? 'w-full h-[56px] mt-[46px]' : 'w-full h-[48px] mt-[36px]', { disable: !checked }]"
        :disabled="bindBtnDisabled"
        @click="bindLogin"
      >{{ $t('siteBuild.header.confirm') }}</Button>
      <Button
        v-if="isMobile && !siteLoginUserInfo"
        size="large"
        :style="newSubBtnMobileColor"
        class="w-full h-[48px] mt-[24px]"
        @click="handleToLogin"
      >{{ $t('siteBuild.header.loginByEmail') }}</Button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { useLoginState, LoginStateEnum } from "./useLogin";
import colorMixin from './colorMixins'
import mobileMixin from './mobileMixins'
import HelperImage from './image'
import EventTrackerMixins from '~/mixins/eventTracker'
import SiteInput from '~/site/components/forms/input'
import Button from '~/site/components/forms/button'
import richText from '~/components/richText'

export default {
  name: 'BindUserForm',

  components: {
    SiteInput,
    Button,
    HelperImage,
    richText
  },

  mixins: [colorMixin, EventTrackerMixins, mobileMixin],

  props: {
    id: {
      type: String,
      default: () => ''
    },

    isBindUserLogin: { // 是否游客登录
      type: Boolean,
      default: false,
    },

    checking: { // 检查中
      type: Boolean,
      default: false,
    },

    logining: { // 登录中
      type: Boolean,
      default: false,
    },

    checked: { // 是否检查用户角色ID
      type: Boolean,
      default: false,
    },

    currUserInfo: { // 当前待绑定用户角色信息
      type: Object,
      default: () => ({}),
    },

    model: {
      type: Object,
      default() {
        return {}
      },
    },

    editing: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    const { setLoginState, } = useLoginState()

    return {
      setLoginState
    }
  },

  computed: {
    ...mapState({
      siteLoginUserInfo: state => state.user.siteLoginUserInfo,
    }),
    bindBtnDisabled() {
      return this.editing ? false : !this.checked
    }
  },

  mounted() {
    this.eventTracker(4)
  },

  methods: {
    clear() {
      this.$emit('clear')
    },

    checkUser() {
      this.$emit('checkUser')
    },

    bindLogin() {
      if (this.editing) return
      this.$emit('bindLogin')
    },

    valueChange(val) {
      this.$emit('update:id', (val || '').toString().trim())
    },

    handleToLogin() {
      this.setLoginState(LoginStateEnum.LOGIN);
      this.$store.commit('user/SET_SITE_BIND_USER', false)
      // this.$logger.captureMessage('点击邮箱登录')
    }
  }
}
</script>

<style lang="less" scoped>
.form-wrap {
  padding: 0 0 0 0;

  .form-item {
    .label {
      font-family: PingFang SC;
      font-size: 14px;
      font-weight: normal;
      line-height: 22px;
      margin-bottom: 8px;
      color: #18171c;
    }

    .input-wrap {
      position: relative;
      border-radius: 2px;
      overflow: hidden;

      .arrow {
        width: 36px;
        height: 36px;
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;

        div {
          width: 100%;
          height: 100%;
        }
      }

      .arrow.active {
        background: rgba(92, 111, 254, 1);

        svg {
          color: #fff;
        }
      }
    }
  }
}

.user-info {
  background: #f0f2f5;
  padding: 12px 16px;

  .user-info-item {
    font-family: PingFangSC-Regular;
    font-size: 14px;
    font-weight: normal;
    line-height: 22px;
    margin-top: 8px;
    color: rgba(24, 23, 28, 0.66);

    &:first-child {
      margin-top: 0px;
    }
  }
}
</style>
