var render = function render(){var _vm=this,_c=_vm._self._c;return _c('widget-content',{staticClass:"store-widget-wrap",attrs:{"bg":_vm.model}},[_c('div',{staticClass:"flex justify-center store-title-rich-wrap"},[(_vm.model.titleVisible)?_c('rich-text',{staticClass:"text-[20px] w-full title",class:_vm.$store.getters.isDesktop && 'xl:text-[40px]',attrs:{"editing":_vm.editing,"disabled":!_vm.editing,"placement":"bottom","theme":"snow"},model:{value:(_vm.model.title),callback:function ($$v) {_vm.$set(_vm.model, "title", $$v)},expression:"model.title"}}):_vm._e()],1),_vm._v(" "),(_vm.isShowLayoutBtn)?_c('div',{staticClass:"store-menu__icon",on:{"click":function($event){_vm.infoMenuVisible = !_vm.infoMenuVisible}}},[(_vm.infoMenuVisible)?_c('InfoMenuIcon'):_c('i',{staticClass:"el-icon-menu"})],1):_vm._e(),_vm._v(" "),(_vm.isShowStoreNav)?_c('div',{staticClass:"nav-container"},[_c('div',{staticClass:"nav-wrap"},_vm._l((_vm.model.stores),function(s){return _c('div',{key:s.key,staticClass:"nav-wrap__item",style:(_vm.groupNameStyle),on:{"click":function($event){return _vm.scrollGoodGroup(s)}}},[_vm._v(_vm._s(s.__groupName))])}),0)]):_vm._e(),_vm._v(" "),_vm._l((_vm.model.stores),function(s){return _c('bg-style',{directives:[{name:"imloading",rawName:"v-imloading",value:(_vm.pushing),expression:"pushing"}],key:s.key,class:{
      'store-item': true,
      empty: !(s.__goods && s.__goods.length)
    },attrs:{"id":`${_vm.model.id}-group-${s.key}`,"bg":{ ...s, ...{ backgroundBlur: _vm.model.backgroundBlur } }}},[(_vm.showGroupName && s.__groupName && !_vm.editing)?_c('div',{staticClass:"group-name"},[_c('span',{style:(_vm.groupNameStyle)},[_vm._v(_vm._s(s.__groupName))])]):_vm._e(),_vm._v(" "),(_vm.showGroupName && _vm.editing)?_c('div',{staticClass:"group-name"},[_c('span',{style:(_vm.groupNameStyle)},[_vm._v("\n        "+_vm._s(s.__groupName || _vm.$t('menu.shopSection', { number: s.key }))+"\n      ")])]):_vm._e(),_vm._v(" "),_c('div',{ref:"store",refInFor:true,class:[
        'flex',
        'store-goods-wrap',
        _vm.isLeftLayout ? 'justify-start' : 'justify-center'
      ]},[(s.select[0].value && s.select[1].value)?_c('div',{class:[
          'flex',
          'flex-wrap',
          'store-goods',
          _vm.leftLayoutNumClassName,
          _vm.isBackgroundGoods ? 'background-goods' : 'normal-goods',
          _vm.isLeftLayout ? 'justify-start' : 'justify-center',
        ]},_vm._l((s.__goods),function(good,index){return _c('OneGood',{key:good.code,attrs:{"is-last-one":index === s.__goods.length - 1,"good":good,"is-mobile":_vm.isMobile,"editing":_vm.editing,"show-car":_vm.showCar,"store-lang":_vm.storeLang,"info-menu-visible":_vm.infoMenuVisible,"model":_vm.model},on:{"buy":_vm.buy}})}),1):_vm._e(),_vm._v(" "),(!s.select[1].value && _vm.editing)?_c('div',{class:[
          'flex',
          'flex-wrap',
          'store-goods',
          'skeleton',
          _vm.leftLayoutNumClassName,
          _vm.isBackgroundGoods ? 'background-goods' : 'normal-goods',
          _vm.isLeftLayout ? 'justify-start' : 'justify-center',
        ]},_vm._l((4),function(good){return _c('OneGood',{key:good,attrs:{"is-tmp":"","good":{names: good, activities: [], id: good},"editing":_vm.editing,"show-car":_vm.showCar,"store-lang":_vm.storeLang,"info-menu-visible":_vm.infoMenuVisible,"model":_vm.model}})}),1):_vm._e()])])}),_vm._v(" "),_c('payresult',{attrs:{"device":_vm.device},on:{"close":function($event){_vm.showpayresult = false}},model:{value:(_vm.showpayresult),callback:function ($$v) {_vm.showpayresult=$$v},expression:"showpayresult"}}),_vm._v(" "),(_vm.deskPay.visible)?_c('DeskPayModal',{attrs:{"desk-pay":_vm.deskPay,"store-lang":_vm.storeLang,"lang-id-data":_vm.langIdData},on:{"close":_vm.closePayModal}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }