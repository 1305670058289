<template>
  <div>
    <Dialog
      :visible.sync="dialogVisible"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :show-close="false"
      custom-class="new_desk_pay_modal"
      width="860px"
    >
      <div class="dialog_box relative">
        <!-- <i
          class="el-icon-close absolute top-3 right-4 cursor-pointer text-black text-16"
          @click="$emit('close')"
        ></i>-->
        <div
          v-loading="loading"
          element-loading-background="rgba(255, 255, 255, 1)"
          :class="containerClass"
        >
          <div v-if="isShowNewUI && !loading" class="good_box" @click="handleClosePaySelect">
            <!-- {{ project.info.project }} -->
            <!-- 名称 -->
            <div class="good_name flex items-center mb-[28px] slide-down">
              <img v-if="projectIcon" :src="projectIcon" class="app_logo" alt />
              <div
                v-if="!projectIcon"
                class="flex items-center justify-center app_logo default_app_logo"
              >
                <img src="~/assets/img/default-app.png" alt />
              </div>
              <span>{{projectName}}</span>
            </div>

            <!-- 用户名称 -->
            <div v-if="gameName" class="game_user_name mb-[22px] slide-down">{{ $t('siteBuild.header.userAccount') }}：{{gameName}}</div>

            <!-- 价格 -->
            <div v-if="payInfo.total_amount" class="pay_price_info mb-6 slide-up">
              <div class="pay_tit">{{ $t('siteBuild.totalAmount') }}</div>
              <div class="pay_total_amount">{{payInfo.currency}} {{payInfo.total_amount}}</div>
              <div class="pay_sub_total">{{ $t('siteBuild.subTotal') }}：{{payInfo.currency}} {{payInfo.amount}}</div>
              <div
                v-if="payInfo && typeof payInfo.with_tax === 'boolean' && !payInfo.with_tax"
                class="pay_sub_total mt-[2px]"
              >{{ $t('siteBuild.tax') }}：{{payInfo.currency}} {{payInfo.taxes_value ? payInfo.taxes_value.toFixed(2) : 0.00}}</div>
            </div>

            <!-- 商品信息 -->
            <div v-if="goodsData.length" class="good_box_cont slide-up">
              <div v-for="good in goodsData" :key="`good_${good.id}`" class="good_detial">
                <div class="mb-6">
                  <div class="good_tit mb-2">{{$t('siteBuild.buyGoods')}}</div>
                  <div class="good_item flex">
<!--                    <div-->
<!--                      v-if="!good.item_icon"-->
<!--                      class="w-[56px] h-[56px] rounded mr-4 flex items-center justify-center default_good_logo"-->
<!--                    >-->
<!--                      <img src="~/assets/img/default-good.png" class="w-[36px] h-[36px]" alt />-->
<!--                    </div>-->
                    <img
                      v-if="good.item_icon"
                      :src="good.item_icon"
                      class="w-[54px] h-[54px] rounded mr-4 good_logo"
                      alt
                    />
                    <div class="flex-1 flex flex-col justify-center">
                      <div class="good_name mb-1">{{ getGoodsName(good.item_name || cardData.label) }}</div>
                      <div class="good_price flex items-center">
                        <div class="w-[120px] mr-6">{{good.currency}} {{good.price}}</div>
                        <div>x{{good.num}}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="good.activity && good.activity.length > 0">
                  <div class="good_tit mb-2">{{$t('siteBuild.gift')}}</div>
                  <div v-for="(item, index) in good.activity" :key="`${good.id}_${index}`">
                    <div
                      v-for="(gift, idx) in item.gift_list"
                      :key="`${good.id}_${index}_${idx}`"
                      class="good_item flex mb-4"
                    >
                      <img :src="gift.logo" alt class="w-[56px] h-[56px] rounded mr-4" />
                      <div class="flex-1 flex flex-col justify-center">
                        <div class="good_name mb-1">{{$t('siteBuild.gift')}}</div>
                        <div class="good_price flex items-center">
                          <div class="w-[120px] mr-6">{{good.currency}} 0.00</div>
                          <div>x{{gift.num}}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="cardData.gift_packs_list && cardData.gift_packs_list.length > 0">
                  <div class="good_tit mb-2">{{$t('siteBuild.gift')}}</div>
                  <div
                    v-for="(item, idx) in cardData.gift_packs_list"
                    :key="`${good.id}_${item}_${idx}`"
                    class="good_item flex mb-4"
                  >
                    <img :src="item.gift_goods_logo" alt class="w-[56px] h-[56px] rounded mr-4" />
                    <div class="flex-1 flex flex-col justify-center">
                      <div class="good_name mb-1">{{$t('siteBuild.gift')}}</div>
                      <div class="good_price flex items-center">
                        <div>x{{item.num}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="pay_box">
            <iframe
              ref="payIframe"
              class="pay-iframe"
              allow="clipboard-write *; payment;"
              frameborder="0"
              :allowFullscreen="true"
              scrolling="auto"
              :src="deskPay.payUrl"
            ></iframe>
          </div>
        </div>
      </div>
    </Dialog>
  </div>
</template>
<script>
import { Dialog } from 'element-ui'

export default {
  name: 'DeskPayModal',
  components: { Dialog },
  props: {
    langIdData: {
      type: Object,
      default() {
        return {}
      }
    },
    cardData: {
      type: Object,
      default() {
        return {}
      }
    },
    deskPay: {
      type: Object,
      default: () => { }
    },
    storeLang: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      dialogVisible: true,
      loading: true,
      payPlatform: '', // 支付平台
      payInfo: {},
      uiVisible: true
    }
  },
  computed: {
    containerClass() {
      return this.isShowNewUI ? 'new_pay_container' : 'pay_container'
    },
    // locale
    locale() {
      return this.$store.state.locale.locale || 'en'
    },
    // 应用信息
    project() {
      return this.$store.state.project || {}
    },
    // 应用名称
    projectName() {
      const names = this.project && this.project.info && this.project.info.project && this.project.info.project.names ? this.project.info.project.names : {}
      return names[this.locale] ? names[this.locale] : names.en ? names.en : ""
    },
    projectIcon() {
      return this.project && this.project.info && this.project.info.project && this.project.info.project.icon ? this.project.info.project.icon : ''
    },
    // 游戏昵称
    gameName() {
      return this.$store.state.user.siteUserInfo && this.$store.state.user.siteUserInfo.name || ''
    },
    // 是否显示新UI
    isShowNewUI() {
      // 新的go服务使用新UI
      return this.deskPay.isGoPay && this.uiVisible
    },
    // 商品
    goodsData() {
      return this.payInfo && this.payInfo.items ? this.payInfo.items : []
    }
  },
  mounted() {
    window.addEventListener("message", this.handleMessage, false);
    this.getOrderDetail()
  },
  beforeDestroy() {
    window.removeEventListener('message', this.handleMessage)
  },
  methods: {
    getGoodsName(name) {
      return this.langIdData[name] || name
    },
    handleClosePaySelect() {
      const $iframe = this.$refs.payIframe
      $iframe && $iframe.contentWindow.postMessage({ type: 'closePayPopover' }, '*')
    },
    handleMessage(e) {
      // eslint-disable-next-line no-console
      console.log('===pay message===', e)
      if (e.data && e.data.type === 'load') {
        // 加载完毕关闭loading
        this.loading = false
      } else if (e.data && e.data.type === 'pay_info') {
        // 获取支付平台
        this.payPlatform = e.data.payPlatform
      } else if (e.data && e.data.type === 'openUrl') {
        // 打开外部链接
        window.open(e.data.url, '_blank')
      } else if (e.data && e.data.type === 'close') {
        // 关闭弹框
        this.$emit('close')
      } else if (e.data && e.data.type === 'setUiVisible') {
        this.uiVisible = e.data.data
      }
    },
    getOrderDetail() {
      if (this.deskPay.orderCode) {
        this.$api.order.getOrderInfo({ code: this.deskPay.orderCode }).then(res => {
          this.payInfo = res
          console.log(res, 'orderInfo')
          console.log(this.cardData, 'cardData')
        })
      }
    }
  }
}
</script>
<style lang="less">
.new_desk_pay_modal {
  .el-dialog__header,
  .el-dialog__footer {
    display: none;
  }
  .el-dialog__body {
    padding: 0;
  }
  .dialog_box {
    width: 860px;
    height: 640px;
    .new_pay_container {
      display: flex;
      width: 100%;
      height: 100%;
      .good_box {
        width: 360px;
        height: 640px;
        padding: 32px 32px 16px;
        overflow-y: auto;
        background: #f7f7f7;
        position: absolute;
        left: 0;
        top: 0;
        .slide-down {
          opacity: 0;
          animation: slideDown 0.24s linear forwards;
        }
        .slide-up {
          opacity: 0;
          animation: slideUp 0.24s linear forwards;
        }
        @keyframes slideUp {
          from {
            transform: translateY(100px);
            opacity: 0;
          }
          to {
            transform: translateY(0px);
            opacity: 1;
          }
        }
        @keyframes slideDown {
          from {
            transform: translateY(-100px);
            opacity: 0;
          }
          to {
            transform: translateY(0px);
            opacity: 1;
          }
        }
        .good_name {
          animation-delay: 50ms;
          .app_logo {
            width: 32px;
            height: 32px;
            border-radius: 16px;
            margin-right: 12px;
          }
          .default_app_logo {
            background: #ececfe;
            > img {
              width: 24px;
              height: 24px;
            }
          }
          > span {
            font-family: PingFang SC;
            font-size: 16px;
            font-weight: bold;
            line-height: 24px;
            color: #18171c;
          }
        }
        .game_user_name {
          font-family: PingFang SC;
          font-size: 14px;
          font-weight: normal;
          line-height: 22px;
          letter-spacing: 0px;
          color: #18171c;
          padding: 8px 12px;
          border-radius: 2px;
          background-color: rgba(61, 70, 245, 0.1);
          animation-delay: 100ms;
        }
        .pay_price_info {
          font-family: PingFang SC;
          font-weight: normal;
          color: #18171c;
          animation-delay: 50ms;
          .pay_tit {
            font-weight: bold;
            font-size: 14px;
            line-height: 22px;
          }
          .pay_total_amount {
            font-weight: bold;
            font-size: 32px;
            line-height: 48px;
          }
          .pay_sub_total {
            font-size: 14px;
            line-height: 22px;
            opacity: 0.5;
          }
        }
        .good_box_cont {
          animation-delay: 100ms;
          .good_tit {
            font-family: PingFang SC;
            font-size: 14px;
            font-weight: normal;
            line-height: 22px;
            color: #18171c;
          }

          .good_item {
            font-family: PingFang SC;
            letter-spacing: 0px;
            color: #18171c;
            .good_name {
              font-size: 16px;
              font-weight: bold;
              line-height: 24px;
            }
            .good_price {
              font-size: 14px;
              line-height: 22px;
              opacity: 0.5;
            }
          }

          .default_good_logo {
            border: 1px solid #ebeef1;
            background: #ffffff;
          }
          .good_logo {
            border: 1px solid #ebeef1;
          }
        }
      }
      .pay_box {
        width: 500px;
        height: 640px;
        position: absolute;
        left: 360px;
        .pay-iframe {
          width: 100%;
          height: 100%;
        }
      }
    }
    .pay_container {
      border-radius: 2px;
      width: 100%;
      height: 100%;
      .pay_box {
        width: 860px;
        height: 640px;
        .pay-iframe {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
