// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".month-card.is-mobile .month-card__button{padding:0 5px;width:100%}.month-card.is-mobile .month-card__content .content-area{width:100%}.month-card.is-mobile .month-card__content .left-thumb{flex-shrink:0;width:50%}.month-card.is-mobile .month-card__content .right-detail{width:50%}.month-card.is-mobile .month-card__content .right-detail .list .item{height:40px;width:40px}.month-card__button{align-items:center;border-radius:var(--button-border-radius-px);cursor:pointer;display:flex;height:32px;justify-content:center;overflow:hidden;width:80px}.month-card__content .content-area{display:flex;justify-content:center;margin:0 auto;width:800px}.month-card__content .content-area .left-thumb{flex-shrink:0;width:240px}.month-card__content .content-area .right-detail{padding-left:20px;width:70%}.month-card__content .content-area .right-detail .list{align-items:center;display:flex;overflow:auto;width:100%}.month-card__content .content-area .right-detail .list .item{flex-shrink:0;height:60px;margin-right:20px;position:relative;width:60px}.month-card__content .content-area .right-detail .list .item .number{bottom:3px;position:absolute;right:5px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"siteFontFamily": "system-ui,sans-serif,math,ui-rounded,ui-monospce"
};
module.exports = ___CSS_LOADER_EXPORT___;
